import React from 'react';
import GoogleLogoSvg from '../svg/google/GoogleLogoSvg';
import RatingStarSvg from '../svg/google/RatingStarSvg';

const GoogleReviewBadge = ({ className }) => (
  <div
    className={`rounded-sm border border-solid border-gray-200 cursor-pointer ${className}`}
    style={{
      boxShadow: '0 10px 28px rgba(0,0,0,.08)'
    }}
  >
    <a
      target="_blank"
      rel="noopener noreferrer"
      href="https://www.google.com/search?q=Timpanogos+Accounting&ludocid=1213101087878108968#lrd=0x0:0x10d5cd031b9d5728,1"
    >
      <div className="w-full h-2 rounded-t-sm bg-primary" />
      <div className="relative p-3 bg-white transition-all ease-in-out duration-200 rounded-sm">
        <GoogleLogoSvg className="inline" />
        <div className="inline-block ml-1 align-middle">
          <div>Google Rating</div>
          <span className="text-xl mr-2 align-middle text-red-600">5.0</span>
          <span>
            <span className="pr-1 leading-6">
              <RatingStarSvg className="inline" />
            </span>
            <span className="pr-1 leading-6">
              <RatingStarSvg className="inline" />
            </span>
            <span className="pr-1 leading-6">
              <RatingStarSvg className="inline" />
            </span>
            <span className="pr-1 leading-6">
              <RatingStarSvg className="inline" />
            </span>
            <span className="pr-1 leading-6">
              <RatingStarSvg className="inline" />
            </span>
          </span>
        </div>
      </div>
    </a>
  </div>
);

export default GoogleReviewBadge;
