import React from 'react';
import SplitSection from '../SplitSection';
import SvgCharts from '../../svg/SvgCharts';
import Accounting from '../../svg/accounting.svg';
import Bookkeping from '../../svg/bookkeeping.svg';

export default () => (
  <section id="about-us">
    <SplitSection
      primarySlot={
        <div className="lg:pr-32 xl:pr-48">
          <h3 className="text-3xl font-semibold leading-tight">Who We Are</h3>
          <p className="mt-8 text-xl font-light leading-relaxed">
            We are a fast-growing CPA firm with years of public and private accounting experience.
            Nobody is going to treat your small business as well as we do. And we are 100% based in
            the USA.
          </p>
        </div>
      }
      secondarySlot={<SvgCharts />}
    />
    <SplitSection
      reverseOrder
      primarySlot={
        <div className="lg:pl-32 xl:pl-48">
          <h3 className="text-3xl font-semibold leading-tight">What We Do</h3>
          <p className="mt-8 text-xl font-light leading-relaxed">
            Taxes, accounting, and analytics - it puts a smile on our face just thinking about it!
            We love small businesses and are engineered to help them grow to reach their highest
            potential.
          </p>
        </div>
      }
      secondarySlot={<img src={Accounting} alt="accounting" />}
    />
    <SplitSection
      primarySlot={
        <div className="lg:pr-32 xl:pr-48">
          <h3 className="text-3xl font-semibold leading-tight">Who We Serve</h3>
          <p className="mt-8 text-xl font-light leading-relaxed">
            We serve small businesses and their owners who are comfortable using technology. We
            believe that we offer a better and more convenient experience by keeping everything
            online and allowing you to work with us from the comfort of your home.
          </p>
        </div>
      }
      secondarySlot={<img src={Bookkeping} alt="bookkeeping" className="mx-auto md:mr-0" />}
    />
  </section>
);
