import React from 'react';
import { Link } from 'gatsby';

import Seo from '../components/Seo';
import Button from '../components/Button';
import GoogleReviewBadge from '../components/GoogleReviewBadge';
import Layout from '../components/layout/Layout';
import HeroImage from '../svg/HeroImage';

import Services from '../components/home/Services';
import AboutUs from '../components/home/AboutUs';
import Testimonials from '../components/home/Testimonials';
import CallToAction from '../components/CallToAction';

export default () => (
  <Layout>
    <Seo />
    <section className="pt-20 md:pt-40">
      <div className="container mx-auto px-8 lg:flex">
        <div className="text-center lg:text-left lg:w-1/2">
          <h1 className="text-4xl lg:text-5xl xl:text-6xl font-bold leading-none">
            Your Professional CPA Firm
          </h1>
          <p className="text-xl lg:text-2xl mt-6 font-light">
            Providing your small business with skilled taxes, accounting, and analytics catered to
            your needs.
          </p>
          <p className="mt-8 md:mt-12">
            <Link to="/contact">
              <Button size="lg">Contact Us</Button>
            </Link>
          </p>
          <p className="mt-4 text-gray-600">Let us know how we can help you</p>
        </div>
        <div className="lg:w-1/2">
          <HeroImage />
        </div>
      </div>
    </section>

    <Services />
    <AboutUs />
    <Testimonials />
    <CallToAction />
    <GoogleReviewBadge className="fixed max-w-none left-0 bottom-0 mb-8 ml-8 hidden md:block" />
  </Layout>
);
