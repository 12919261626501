import React from 'react';
import { Link } from 'gatsby';
import Card from '../Card';
import servicesData from '../../data/services-data';

export default () => (
  <section id="services" className="py-20 lg:pb-40 lg:pt-48">
    <div className="container mx-auto text-center">
      <h2 className="text-3xl lg:text-5xl font-semibold">Services</h2>
      <div className="flex flex-col flex-wrap sm:flex-row sm:-mx-3 mt-12">
        {servicesData.map(service => (
          <div key={service.slug} className="flex flex-1 md:flex-none md:w-1/3 px-3">
            <Link
              to={`/services#${service.slug}`}
              className="flex text-black mb-8 hover:bg-blue-100 ease-in-out duration-300 transition-colors"
            >
              <Card>
                <p className="font-semibold text-xl">{service.title}</p>
                <p className="mt-4">{service.landingSummary}</p>
              </Card>
            </Link>
          </div>
        ))}
      </div>
    </div>
  </section>
);
