import Amy from '../images/amy_harrison.jpg';
import Brindee from '../images/brindees_corner.jpg';
import Curtis from '../images/flyfishfood.jpg';

/*
- Amy Harrison picture
*/

export default [
  {
    title: 'I always trust them to handle my taxes',
    content:
      'Mike has been my accountant since I have started my business as a cosmetologist. Being self-employed I wasn’t quite sure where to start. Mike sat down with me and we talked about my business and we figured out the best strategy for my taxes. Mike is very educated and honest. Over the past few years I have felt confident and so pleased with how easy he made the whole process.',
    customerName: "Brindee's Corner LLC",
    customerTitle: 'Cosmetologist',
    customerImage: Brindee
  },
  {
    title: 'He makes it so easy!',
    content:
      'Mike has done our taxes for 2 years and as a new small business owner, I really appreciated his help navigating that side of taxes when we first started working with him. He is always really responsive to emails and takes time to answer any questions. I LOVE that I can do it all online, submit documents as I get them and do it all from home!!!',
    customerName: 'Amy Harrison',
    customerTitle: 'Small Business Owner',
    customerImage: Amy
  },
  {
    title: 'A++ Option for your taxes.',
    content:
      "We're a small growing business and Mike knows his stuff. He's always looking out for us and is always looking to maximize our benefit. Speedy turn-arounds and great overall service. Can't go wrong!",
    customerName: 'Curtis Fry',
    customerTitle: 'Fly Fish Food',
    customerImage: Curtis
  },
  // Testimonials past this point do not render
  {
    title: 'They understood my needs',
    content:
      'Great customer service and very knowledgeable about tax codes for my real estate company and home rentals. I would recommend Mike to anyone!',
    customerName: 'One Thumb Papa LLC',
    customerTitle: 'Business Owner',
    customerImage: 'https://placeimg.com/150/150/people'
  }
];
